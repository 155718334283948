<template>
  <div>
    <b-card
      v-if="isReportInProgress"
      bg-variant="gray-200"
      class="mb-3 mb-lg-5 border-0"
    >
      <p class="mb-0">
        Report acquistato. L'analisi è in creazione, attendere prego.
      </p>
    </b-card>
    <b-card
      v-else
      border-variant="warning"
      class="mb-3 mb-lg-5"
    >
      <div class="buy-background">
        <b-button
          variant="info"
          size="lg"
          class="center-button"
          @click="buyReport"
        >
          Acquistare Click Report
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'ProfileWarning',
  components: {},
  props: {
    isReportInProgress: Boolean,
    buyClickReport: Function,
  },
  computed: {},
  methods: {
    buyReport() {
      if (this.$props.buyClickReport) {
        this.$props.buyClickReport();
      }
    },
  },
};
</script>

<style scoped>
.buy-background{
  min-height: 350px;
  width: 100%;
  height: 100%;
  background: url('~@/assets/img/buy-clickreport.jpg') no-repeat center center;
  background-size: 100% 100%;
}

.center-button{
  position: absolute;
  top: 40%;
  left: 40%;
  margin: auto;
}
</style>
